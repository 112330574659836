import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation, { MenuButton } from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'

const RueFestive = () => {
  const metaTitle = 'Rue Festive | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'rue, festive, festival, cirque, amuseur, festif, divertissement'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Rue Festive Télé-Québec"
      />
      <div className="container pb-12">
        <div className="content">
        <h1 className='lg:!text-[60px] !text-[50px] pb-6'>19 JUILLET DE 12h À 18h</h1>

          {/* TOP VIDEO */}
          <div className="max-w-[1920px] justify-center mx-auto">
            <center>
              <div className="text-center w-full">
                <video muted autoPlay loop playsInline className="rounded-3xl">
                  <source src="https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdgt0MTzAJOCiiO_rue-festive.mp4" type="video/mp4" />
                </video>
              </div>
            </center>
          </div>

          {/* HIGHLIGHTED TEXT */}
          <div className='my-12 text-center'>
            <h5>La Rue Festive Télé-Québec rend hommage à la Fête Foraine de Baie-Saint-Paul, qui a vu le jour dans les années 80 et qui s’est ensuite métamorphosée pour devenir l’emblématique Cirque du Soleil. Acrobates, jongleur·euses, fanfares et musicien·nes ambulant·es prennent d’assaut la rue Saint-Jean-Baptiste, en plein cœur du Festif!. Profite de la dizaine d’artistes et de troupes venu·es des quatre coins de la planète en famille ou entre ami·es! On te donne rendez-vous dans cette ambiance déjantée et familiale!</h5>
          </div>

        </div>

        <div className='hidden'>
          <div className="billetterie flex flex-wrap w-full">

            <div className="md:w-1/6 w-full text-center px-4 md:my-0 my-2"></div>
            {/* ANIMATION AMBULANTE */}
            {/* <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-red-wolf rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3">Animation<br/>Ambulante</h3>
                </div>
                <div className="text-red-wolf py-4 px-6">
                  <p className="mb-4"><b>12h00</b><br/><a className="hover:underline" href="/artistes/piero-velo">Piero Vélo</a></p>
                  <p className="mb-4"><b>12h00</b><br/><a className="hover:underline" href="/artistes/homs-fums">HOMS FUMS</a></p>
                  <p className="mb-4"><b>12h45</b><br/><a className="hover:underline" href="/artistes/les-gargouilles">Les Gargouilles</a></p>
                  <p className="mb-4"><b>13h45</b><br/><a className="hover:underline" href="/artistes/homs-fums">HOMS FUMS</a></p>
                  <p className="mb-4"><b>14h00</b><br/><a className="hover:underline" href="/artistes/hanba">Hanba!</a></p>
                  <p className="mb-4"><b>14h45</b><br/><a className="hover:underline" href="/artistes/les-gargouilles">Les Gargouilles</a></p>
                  <p className="mb-4"><b>15h30</b><br/><a className="hover:underline" href="/artistes/bosko-baker">Bosko Baker</a></p>
                  <p className="mb-4"><b>16h15</b><br/><a className="hover:underline" href="/artistes/homs-fums">HOMS FUMS</a></p>
                  <p className="mb-4"><b>16h30</b><br/><a className="hover:underline" href="/artistes/hanba">Hanba!</a></p>
                  <p className="mb-4"><b>17h15</b><br/><a className="hover:underline" href="/artistes/les-gargouilles">Les Gargouilles</a></p>
                </div>
              </div>
            </div> */}

            {/* RUE DE LA TANNERIE */}
            <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-blue-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3">Rue de la<br/>Tannerie</h3>
                </div>
                <div className="text-blue-wolf2024 py-4 px-6">
                  <p className="mb-4"><b>12h00</b><br/><a className="hover:underline" href="/artistes/good-trouble-brass-band">Good Trouble Brass Band</a></p>
                  <p className="mb-4"><b>13h15</b><br/><a className="hover:underline" href="/artistes/lemon-bucket-orkestra">Lemon Bucket Orkestra</a></p>
                  <p className="mb-4"><b>14h00</b><br/><a className="hover:underline" href="/artistes">Mathilde Fortier</a></p>
                  <p className="mb-4"><b>14h30</b><br/><a className="hover:underline" href="/artistes/good-trouble-brass-band">Good Trouble Brass Band</a></p>
                  <p className="mb-4"><b>15h45</b><br/><a className="hover:underline" href="/artistes/lensemble-klezmer-de-sainte-nigoune">L'Ensemble Klezmer de Ste-Nigoune</a></p>
                  <p className="mb-4"><b>16h30</b><br/><a className="hover:underline" href="/artistes/good-trouble-brass-band">Good Trouble Brass Band</a></p>
                </div>
              </div>
            </div>

            {/* RUE JEAN-TALON */}
            <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3">Rue<br/>Jean-Talon</h3>
                </div>
                <div className="text-green-wolf2024 py-4 px-6">
                  <p className="mb-4"><b>12h00</b><br/><a className="hover:underline" href="/artistes/julia-perron">Julia Perron</a></p>
                  <p className="mb-4"><b>13h00</b><br/><a className="hover:underline" href="/artistes/vague-de-cirque">Vague de cirque</a></p>
                  <p className="mb-4"><b>14h00</b><br/><a className="hover:underline" href="/artistes/julia-perron">Julia Perron</a></p>
                  <p className="mb-4"><b>15h00</b><br/><a className="hover:underline" href="/artistes/vague-de-cirque">Vague de cirque</a></p>
                  <p className="mb-4"><b>16h00</b><br/><a className="hover:underline" href="/artistes/julia-perron">Julia Perron</a></p>
                  <p className="mb-4"><b>17h00</b><br/><a className="hover:underline" href="/artistes/vague-de-cirque">Vague de cirque</a></p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className='list-none flex justify-center items-center flex-wrap max-w-[1200px] mx-auto mt-6'>
          <MenuButton />
        </div>

        {/* SPONSORS */}
        {/* <div className="content pt-12">
          <h2 className="!ml-0 !pl-0">Merci à nos partenaires</h2>
          <div className="max-w-[175px] py-4 ml-4">
            <a href="https://www.telequebec.tv/">
              <StaticImage src="../../images/partenaires/telequebec.png" alt="Logo Télé-Québec" imgClassName=""/>
            </a>
          </div>
        </div> */}

        {/* SPONSOR BANNER */}
        <div className="w-full center py-8">
          <div className="max-w-[600px] max-h-[235px] mx-auto">
            <a className="" href="https://video.telequebec.tv/" rel="noreferrer noopener">
              <StaticImage src="../../images/partenaires/telquebec-banner.jpg"/>
            </a>
          </div>
        </div>
      </div>

    </Wolflayout>
  )
}

export default RueFestive
